<template>
  <div v-if="step == 1">
    <div id="preLoading" v-if="preLoading">
      {{ $gettext('Caricamente informazioni in corso....') }}
    </div>
    <div id="container" v-if="!preLoading">
      <div id="intro">
        {{ $gettext('Per inserire una nuova ricetta compila il form qui sotto.') }}
      </div>
      <div id="form">
        <custom-input
          :placeholder='$gettext("Titolo")'
          :label='$gettext("Titolo")'
          :value="titolo"
          type="text"
          @input="(val) => (titolo = val)"
        />
        <div id="categoria_div">
          <h3>{{ $gettext('Categoria') }}</h3>
          <select class="categoria" v-model="categoria" required>
            <option value="-1" selected disabled>
              {{ $gettext('Categoria di appartenenza') }}
            </option>
            <option
              v-for="item in categories"
              v-bind:key="item.index"
              :value="item.id"
              :selected="item.index == 0"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div id="ingredienti_div">
          <h3>{{ $gettext('Ingredienti') }}</h3>
          <template
            v-for="(ingrediente, index) in ingredienti"
            :key="index"
            class="ingredientiList"
          >
            <custom-input
              :label='$gettext("Ingrediente")'
              :value='$gettext("Ingrediente")'
              type="text"
              @input="(val) => (ingredienti[index] = val)"
            />
          </template>
          <div class="button">
            <button class="add-ingredient" @click="aggIngrediente"></button>
          </div>
        </div>
        <div id="corpo_div" class="textarea-container">
          <h3>{{ $gettext('Procedimento') }}</h3>
          <custom-textarea
            :label='$gettext("Inserisci il procedimento")'
            name="descrizione"
            :value="corpo"
            @input="(val) => (corpo = val)"
          ></custom-textarea>
        </div>
        <div id="images_div">
          <h3>{{ $gettext('Immagini') }}</h3>
          <p>{{ $gettext('La prima immagine verrà utilizzata come immagine principale') }}</p>
          <div id="img_div">
            <div class="imageplus" @click="addImage"></div>
            <div class="image" v-for="item in imageItems" :key="item.id">
              <div class="img">
                <img :src="item.src" />
              </div>
              <div class="delete" @click="remove(item.id)"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div
            v-if="errorMessageImage != ''"
            class="error"
            v-html='$gettext(errorMessageImage)'
          ></div>
        </div>

        <div class="button send">
          <button @click="send" :disabled="/*noSend*/ false">
            {{ $gettext('Invia ricetta') }}
          </button>
        </div>
        <div class="error" v-html='$gettext(errorMessage)' v-if="errorMessage"></div>
        <div class="loadingMessages" v-html='$gettext(loadingMessages)'></div>
        <input
          type="file"
          name="img"
          id="input_img"
          @change="loadImage"
          multiple
          style="display: none"
        />
      </div>
    </div>
  </div>
  <div v-if="step == 2" class="container">
    <h1>{{ $gettext('Inserimento effettuato') }}</h1>
    {{ $gettext('Grazie per aver aggiunto una ricetta alla nostra collezione. Al momento è in fase di approvazione.') }}
  </div>
</template>

<script>
/**
 * prendere le categorie delle ricette
 */
import customInput from '@/components/shared/custom-input';
import customTextarea from '@/components/shared/custom-textarea';
import { createGettext } from "vue3-gettext";

export default {
  name: 'RecipeNew',
  components: { customInput, customTextarea },
  data() {
    return {
      preLoading: false,
      categories: [],
      titolo: '',
      ingredienti: [],
      corpo: '',
      categoria: -1,
      imageItems: [],
      featured_media: 0,
      galleryImages: [],
      loadingMessages: '',
      errorMessage: '',
      step: 1,
      errorMessageImage: '',
    };
  },
  methods: {
    aggIngrediente() {
      this.ingredienti.push('');
    },
    async getCategories() {
      try {
        let response = await this.axios.get(this.api.categoriesRecipe+"?" + this.$store.getters['auth/getLanguageRest']);
        const categories = [];
        response.data.forEach((item, index) => {
          categories.push({ id: item.id, name: item.name, index: index });
        });
        this.categories = categories;
      } catch (error) {
        console.log(error);
      }
    },
    async send() {
      this.errorMessage = '';

      if (this.imageItems.length == 0) {
        this.errorMessage = '&Eacute; necessario inserire almeno una immagine';
        return;
      }

      const unresolvedPromises = []; // array of promises
      this.loadingMessages = 'Caricamento immagini';
      this.imageItems.forEach((item, index) => {
        let formData = new FormData();
        const name = item.name;

        formData.append('name', item.name);
        formData.append('file', item.orig);

        const token = this.$store.getters['auth/getToken'];

        let config = {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'filename=' + name,
          },
        };

        const promise = this.axios.post(this.api.sendMedia, formData, config);
        unresolvedPromises.push(promise);
      });

      const resolvedPromises = Promise.all(unresolvedPromises)
        .then((res) => {
          res.forEach((response, index) => {
            //Salvo l'id del backoffice perchè dopo devo legarlo alla ricetta
            if (index == 0) {
              this.featured_media = response.data.id;
            } else {
              this.galleryImages.push(response.data.id);
            }
            //Potrebbe non servire più.
            this.imageItems[index].idR = response.data.id;
          });

          this.creaRicettaPost();
        })
        .catch((error) => {
          this.errorMessage =
            $gettext('Non siamo riusciti a caricare la tua ricetta. Per favore prova tra qualche minuto');
          this.loadingMessages = '';
        });

      return;
    },
    async creaRicettaPost() {
      this.loadingMessages = 'Creazione ricetta...';
      try {
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters['auth/getToken'],
            'Content-Type': 'application/json',
          },
        };
        let response = await this.axios.post(
          this.api.getRecipeListing,
          {
            title: this.titolo,
            content: this.corpo,
            featured_media: this.featured_media,
            categoria_ricetta: this.categoria,
            acf: {
              ingredienti: this.ingredienti.join('\n'),
              gallery_pagine: this.galleryImages,
            },
          },
          config,
        );
        this.step = 2;
      } catch (error) {
        console.log(error.response);
        this.loadingMessages = '';
        this.errorMessage =
          'Non siamo riusciti a caricare la tua ricetta. Per favore prova tra qualche minuto.';
      }
    },
    addImage() {
      const elem = document.getElementById('input_img');
      elem.click();
    },
    remove(id) {
      this.imageItems.splice(id, 1);
      this.imageItems.forEach((item, index) => {
        this.imageItems[index].id = index;
      });
    },
    loadImage(event) {
      Array.prototype.forEach.call(event.target.files, (item) => {
        if (item.size > 10 * 1048576) {
          this.errorMessageImage =
            $gettext('Alcune immagini non sono state caricate perchè la loro dimensione supera i 10 MB');
        } else {
          this.imageItems.push({
            id: this.imageItems.length,
            src: URL.createObjectURL(item),
            orig: item,
            idR: 0,
          });
          this.progress = 0;
          this.message = '';
        }
      });
      event.target.value = '';
      return true;
    },
  },
  computed: {
    noSend() {
      if (
        this.loadingMessages != '' ||
        this.imageItems.length == 0 ||
        this.ingredienti.length == 0 ||
        this.categoria == -1 ||
        this.titolo.trim() == '' ||
        this.corpo.trim() == ''
      )
        return true;
      return false;
    },
  },
  mounted() {
    if (!this.$store.getters['auth/isLoggedIn']) {
      this.$router.replace('/');
      return;
    }

    this.getCategories();
  },
};
</script>

<style scoped>
h3 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 600;
}

p {
  margin-bottom: 30px;
}

button {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  width: 46px;
  height: 46px;
  border: 0;
  border-radius: 1000px;
}

select.categoria {
  appearance: none;
  padding: 15px 20px;
  border-radius: 100px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  border: 0;
  width: 85%;
  font-family: Barlow;
  font-size: 1rem;
  background-image: url('../../assets/icons/down-caret.svg');
  background-position: calc(100% - 20px) calc(50% + 2px);
  background-size: 20px;
  background-repeat: no-repeat;
}

select option[disabled] {
  color: gray;
}

.delete {
  position: absolute;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #3a9691;
  top: 5px;
  right: 5px;
  border-radius: 100px;
  background-image: url('../../assets/icons/close-white.svg');
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}

.add-ingredient {
  background-image: url('../../assets/icons/plus.svg');
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.button.send button {
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 16px;
  text-align: center;
  font-size: 18px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

#form {
  padding-bottom: 30px;
}

#intro {
  margin: 0 0 20px;
  padding: 40px 5%;
  background-color: var(--var-primary-color);
  color: #fff;
  font-weight: 700;
}

#field-container,
#categoria_div,
#ingredienti_div,
#corpo_div,
#images_div {
  max-width: 90%;
  margin: 0 auto;
  padding: 30px 0;
  border-top: 1px solid var(--var-secondary-color);
  box-sizing: border-box;
}

#field-container {
  border-top: 0;
  padding-top: 10px;
}

.ingredientiList {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.image {
  width: 30%;
  height: 150px;
  max-height: 150px;
  margin-bottom: 10px;
  float: left;
  border: 1px solid #ccc;
  margin-left: 2%;
  overflow: hidden;
  position: relative;
}

.image .delete {
  position: absolute;
}

#img_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.image {
  width: 100%;
  height: 150px;
  border-radius: 16px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border: 0;
}

.image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.imageplus {
  width: 100%;
  height: 150px;
  padding: 0;
  background-color: var(--var-primary-color);
  border-radius: 16px;
  border: 0;
  background-image: url('../../assets/icons/plus.svg');
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
</style>
